.inverted-right{
    -webkit-transform: rotateY(-30deg) skewY(-15deg);
    -ms-transform:rotateY(-30deg) skewY(-15deg);
    -moz-transform:rotateY(-30deg) skewY(-15deg);
    -o-transform:rotateY(-30deg) skewY(-15deg);
    transform:rotateY(-30deg) skewY(-15deg);
    transition: ease all 3s;
}


.inverted-left{
    -webkit-transform: rotateY(30deg) skewY(15deg);
    -ms-transform:rotateY(30deg) skewY(15deg);
    -moz-transform:rotateY(30deg) skewY(15deg);
    -o-transform:rotateY(30deg) skewY(15deg);
    transform:rotateY(30deg) skewY(15deg);
    transition: ease all 3s;
}



.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #3ba666;

  }
  .hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 0.8vh;
    bottom: 0;
    left: 0;
    background-color: #3ba666;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }
  .hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }




  .slideout-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 256px;
    min-height: 100vh;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    z-index: 0;
    display: none;
  }
  
  .slideout-menu-left {
    left: 0;
  }
  
  .slideout-menu-right {
    right: 0;
  }
  
  .slideout-panel {
    position: relative;
    z-index: 1;
    will-change: transform;
    background-color: #FFF; /* A background-color is required */
    min-height: 100vh;
  }
  
  .slideout-open,
  .slideout-open body,
  .slideout-open .slideout-panel {
    overflow: hidden;
  }
  
  .slideout-open .slideout-menu {
    display: block;
  }


  .message-div{
    clip-path: polygon(0% 0%, 100% 0%, 100% 75%, 75% 75%, 100% 100%, 50% 75%, 0% 75%);
  }