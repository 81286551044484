
$fa-font-path: "/webfonts";
@import "../node_modules/@fortawesome/fontawesome-free/scss/fontawesome";

@import "../node_modules/@fortawesome/fontawesome-free/scss/brands";
@import "../node_modules/@fortawesome/fontawesome-free/scss/regular";
@import "../node_modules/@fortawesome/fontawesome-free/scss/solid";
@import "../node_modules/@fortawesome/fontawesome-free/scss/v4-shims";
@import "../node_modules/animate.css/animate.min.css";

@tailwind base;
@import "./custom-base.css";
@tailwind components;
@import "./custom-components.css";
@tailwind utilities;
@import "./custom-utilites.css";
@import "./termynal.css";
